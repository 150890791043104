<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div :style="style">
      <v-checkbox
        v-model="value"
        :disabled="disabled"
        dense
        :hide-details="false"
        :class="`${classes}`"
      />
    </div>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeCheckbox',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
        this.dataViewer.background_color ||
        this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return 'mt-0 py-1 d-inline-flex' + this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        return builder.getCellValue(index, this.cell, this.clone, false)
      },
      set(value) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        const previousValue = builder.getCellValue(index, this.cell, this.clone, false)
        builder.evalValues({
          index: this.checklist.index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.hasOwnProperty('value')) {
            return
          }
          this.value(/^(true|1)$/i.test(result.value))
        })
      }
    }
  }
}
</script>
